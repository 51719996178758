<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="9">
                        <b-card v-if="Object.keys(mhd_product).length > 0">
                            <b-card-body class="">
                                <h3 class="text-primary">{{mhd_product.name}}</h3>
                                <div class="text-center" v-html="mhd_product.description">
                                </div>
                                <b-form @submit.prevent="goCheckout">
                                    <hr>
                                    <b-col cols="12">
                                        <template v-if="mhd_product.options.filter(option => option.is_active == 1).length">
                                            <b-form-group
                                                :label="$t('Options')"
                                                label-for="v-options"
                                            >
                                                <template v-for="(product_option , i) in mhd_product.options.filter(option => option.is_active == 1)">
                                                    <p :key="i">
                                                        <b-form-radio
                                                            v-model="product_option_selected"
                                                            name="some-radios"
                                                            :value="product_option.id"
                                                        >
                                                            {{product_option.name}} - {{product_option.price}} {{$t('SAR')}}
                                                        </b-form-radio>
                                                    </p>
                                                </template>
                                            </b-form-group>
                                        </template>
                                        <template v-else>
                                            {{mhd_product.price}} {{$t('SAR')}}
                                        </template>
                                    </b-col>

                                    <!-- submit -->
                                    <b-col cols="12" class="text-right p-0">
                                        <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        type="submit"
                                        variant="primary"
                                        >
                                            {{$t('Go to Purchase')}}
                                        </b-button>
                                    </b-col>
                                </b-form>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    mounted() {
        this.getResults()
    },
    data() {
        return {
            mhd_product: {},
            product_option_selected: null,
            isLoading: true,
        }
    },
    methods: {
        getResults() {
            useJwt.post('/mhd-products/get-by-id',{id: this.$route.params.id})
            .then((response) => {
                // console.log(response.data)
                this.mhd_product = response.data.data
                this.isLoading = false
            })
            .catch(response => {
                // console.log(response);
            });
        },
        goCheckout() {
            this.$router.push({ name: 'mhd-store-checkout', params: { product_id: this.$route.params.id, product_option_id: this.product_option_selected  || 0} })
        }
    },
}
</script>

<style lang="sass" scoped>

</style>
